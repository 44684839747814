import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'wrapper',
        component: () => import('../views/WebsiteView/WrapperView.vue'),
        children: [
        {
            path: '',
            name: 'home',
            component: () => import('../views/WebsiteView/HomeView.vue')
        }, {
            path: 'advertise',
            name: 'advertise',
            component: () => import('../views/WebsiteView/AdvertiseView.vue')
        }, {
            path: 'distribute',
            name: 'distribute',
            component: () => import('../views/WebsiteView/DistributeView.vue')
        }, {
            path: 'faq',
            name: 'faq',
            component: () => import('../views/WebsiteView/FaqView.vue')
        }, {
            path: 'contactus',
            name: 'contactus',
            component: () => import('../views/WebsiteView/ContactusView.vue')
        }
        ]
    }, {
        path: '/auth',
        name: 'authentication',
        component: () => import('../views/AuthView/AuthView.vue'),
        children: [
            {
                path: 'check-data',
                name: 'check data',
                component: () => import('../views/AuthView/ConversationView.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    }
})

export default router
