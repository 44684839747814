<template>
  	<router-view />
</template>

<style lang="scss">
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	body {
		font-family: 'Montserrat', sans-serif;
		color: #4c4c4c;
		line-height: 1.8;
		font-weight: 400;
		width: 100vw;
		overflow-x: hidden;
	}
	.container {
		max-width: 1425px;
		padding: 0 100px;
		display: inline-block;
		width: 100%;
		margin: 100px auto;
	}
	.text-center{ 
		text-align: center;
	}
	.d-flex , .f-flex{
		display: flex;
		&.align-center{
			align-items: center;
		}
		&.justify-center{
			justify-content: center;
		}
		&.justify-right{
			justify-content: right;
		}
		&.justify-s-around{
			justify-content: space-around;
		}
		&.justify-s-between{
			justify-content: space-between;
		}
		.eql-width{
			flex: 1;
		}
	}
	a {
		text-decoration: none;
	}
	button {
		outline: none;
		border: 0;
		padding: 8px 16px;
		border-radius: 4px;
		cursor: pointer;
		font-family: 'Montserrat', sans-serif;
		&.filled-btn {
            background: #67c8f0;
            color: #fff;
        }
		&.outline-btn {
			background: #fff;
			font-weight: 600;
			color: #000;
			border: 2px solid #000;
		}
		&.flat-btn{
			background: transparent;
			border-radius: 50%;
		}
	}
	.contact-section {
		border: 1px solid #ccc;
		padding: 25px;
		input, textarea {
			&:focus-visible, &:focus{
				outline: #67c8f0;
				border: 2px solid #67c8f0;
			}
			border: 0;
			background: #fafafa;
			display: block;
			width: 100%;
			padding: 16px;
			border-radius: 2px;
		}
		.form-element {
			margin-bottom: 20px;
		}
	}
	.parallax {
		position: relative;
		color: #fff;
		overflow-y: hidden;
		background-size: cover;
		img {
			position: absolute;
			top: -30px;
			width: 100%;
		}
		.parallax-content {
			position: relative;
			z-index: 9;
		}
	}
	.distribute-section .parallax img {
		filter: brightness(0.4);
	}

	@media screen and (max-width: 1000px) {
		.d-flex{
			display: block;
		}
		.container {
			padding: 0 20px;
		}
		.parallax img{
			position: static;
		}
	}
</style>
